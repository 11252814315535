import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './views/home/home.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent }, // Default Home route
  {
    path: '',
    redirectTo: 'home', // Redirect to HomeComponent by default
    pathMatch: 'full', // Ensures exact path matches only
  },
  {
    path: '',
    children: [
      {
        path: 'vigi',
        loadChildren: () =>
          import('./views/vigi/vigi.module').then((m) => m.VigiModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
