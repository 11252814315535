import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.css']
})
export class ProductSelectionComponent {

  selectedModule: string = '';
  products = [];
  lifeProductsDropdownOptions: any = [];
  healthProductsDropdownOptions: any = [];
  lifeProducts: any = [];
  healthProducts: any = [];
  showOptions: boolean = false;
  showForm: boolean = true;
  @Output() selectedStatus = new EventEmitter();
  isDropdownOpen = false;
  pitchHtml: any;
  reasonHtml: any;
  isHealthSelected: boolean = false;
  history: any = [];
  $historySubscription: Subscription;
  lastRoute: any;

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onProductChange(event: any) {
    this.onSubmit();
  }

  onModuleSelection(module: string) {
    if (module === 'life')
      this.products = this.lifeProductsDropdownOptions;
    else
      this.products = this.healthProductsDropdownOptions
  }

  productForm: FormGroup;

  constructor(private fb: FormBuilder, public commonService: CommonService, private router: Router,
    private apiService: ApiService) {
    this.productForm = this.fb.group({
      productType: ['life', Validators.required],
      product: ['', Validators.required],
      moduleType: ['sales-pitch', Validators.required]
    });
    this.$historySubscription = commonService.history.subscribe((res) => {
      this.history = res;
      this.lastRoute = this.history[this.history.length - 1];
    })
  }

  ngOnInit(): void {
    this.getLifeProducts();
    this.getHealthProducts();
    this.commonService.resetAllSubjects();
  }

  getLifeProducts() {
    this.apiService.getProducts('life').subscribe({
      next: (res) => {
        this.lifeProductsDropdownOptions = this.convertToDropdownOptions(res);
        this.lifeProducts = res;
        this.products = this.lifeProductsDropdownOptions;
        this.commonService.setLifeProducts(res);
      },
      error: (err) => {
        console.error('Error fetching products:', err);
      }
    })
  }

  getHealthProducts() {
    this.apiService.getProducts('health').subscribe({
      next: (res) => {
        this.healthProductsDropdownOptions = this.convertToDropdownOptions(res);
        this.healthProducts = res;
        this.commonService.setHealthProducts(res);
      },
      error: (err) => {
        console.error('Error fetching products:', err);
      }
    })
  }

  onSubmit() {
    if (this.productForm.valid) {
      let company: string;
      if (this.productForm.get('productType').value === 'life') {
        let selectedItem = this.lifeProducts.filter(p => p.product === this.productForm.get('product').value);
        company = selectedItem[0].company;
      } else {
        let selectedItem = this.healthProducts.filter(p => p.product === this.productForm.get('product').value);
        company = selectedItem[0].company;
      }
      let selectedProduct = {
        productType: this.productForm.get('productType').value,
        product: this.productForm.get('product').value,
        moduleType: this.productForm.get('moduleType').value,
        company: company
      }

      this.commonService.setProduct(selectedProduct);
      localStorage.setItem("selectedProduct",JSON.stringify(selectedProduct));
      this.updateHistory();
      if (this.productForm.get('moduleType').value === 'sales-pitch')
        this.router.navigate(['/vigi/sales-pitch']);
      else
        this.router.navigate(['/vigi/product-queries']);
    } else {
      this.productForm.markAllAsTouched();
    }
  }

  convertToDropdownOptions(data) {
    return data.map(item => ({
      label: item.company + " - " + item.product,
      value: item.product
    }));
  }

  toggleSelection() {
    this.isHealthSelected = !this.isHealthSelected;
    this.productForm.get('productType').setValue(this.isHealthSelected ? 'health' : 'life');
    this.onModuleSelection(this.isHealthSelected ? 'health' : 'life');
  }

  toggleModuleSelection(module: String) {
    this.productForm.get('moduleType').setValue(module);
    if (module === 'product-queries' || module === 'sales-pitch') {
      this.showForm = true;
      this.showOptions = false;
    }
    else {
      this.showForm = false;
      this.showOptions = true;
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const clickedInside = target.closest('.navigation-options') || target.closest('.toggle-button');

    if (!clickedInside && this.isDropdownOpen) {
      this.isDropdownOpen = false;
    }
  }

  onTypeSelectionForProductRecommendation(type: String) {
    this.productForm.get("productType").setValue(type);
    let selectedProduct = {
      productType: this.productForm.get('productType').value,
      product: "",
      moduleType: this.productForm.get('moduleType').value,
      company: ""
    }
    this.commonService.setProduct(selectedProduct);
    localStorage.setItem("selectedProduct",JSON.stringify(selectedProduct));
    this.updateHistory();
    this.router.navigate(['/vigi/product-recommendation']);
  }

  goBack() {
    if (this.lastRoute === 'vigi/product-selection') {
      this.history.pop();
      this.commonService.history.next(this.history);
    }
    this.router.navigate(['/home'])
  }

  ngOnDestroy() {
    this.$historySubscription.unsubscribe();
  }

  updateHistory() {
    if (this.lastRoute != 'vigi/product-selection' || this.lastRoute === undefined) {
      this.history.push("vigi/product-selection");
      this.commonService.history.next(this.history);
    }
  }
}


