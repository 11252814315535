import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleApiService {
  private apiKey = environment.googleApiKey;

  constructor(private http: HttpClient) {}

  // Translate Text API
  translateText(text: string, targetLanguage: string) {
    const url = `https://translation.googleapis.com/language/translate/v2?key=${this.apiKey}`;
    const body = {
      q: text,
      target: targetLanguage
    };
    return this.http.post(url, body);
  }
}
