<app-product-details-life [selectedProduct]="selectedProduct" [pageName]="pageName"
    *ngIf="selectedProduct.productType==='life' && (!showProductRecommendation && !isSkipped)"
    (customerDetails)="customerDetailsForLife($event)"></app-product-details-life>
<app-product-details-health [selectedProduct]="selectedProduct" [pageName]="pageName"
    *ngIf="selectedProduct.productType==='health' && (!showProductRecommendation && !isSkipped)"
    (customerDetails)="customerDetailsForHealth($event)"></app-product-details-health>

<div *ngIf="showProductRecommendation">
    <header>
        <div class="container mx-auto flex items-center justify-between">
            <!-- Logo and Name -->
            <div class="flex items-center">
                <button (click)="goBack()" class="focus:outline-none">
                    <svg class="h-8 w-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M19 12H5M12 19l-7-7 7-7"></path>
                    </svg>
                </button>
                <span class="text-xl ml-2 font-bold">Product Recommendation</span>
            </div>
            <!-- Kebab Icon -->
            <div class="flex items-center">
                <button (onblur)="toggleDropdown()" (click)="toggleDropdown()" class="focus:outline-none toggle-button">
                    <img class="h-6 w-6" src="../../../../assets/icons/menu.png" />
                </button>
                <div [ngClass]="{'dropdown-open': isDropdownOpen}"
                    class="header-wrapper absolute bg-white text-black rounded-md shadow-lg overflow-hidden">
                    <div class="navigation-options">
                        <span routerLink="/home" class="block px-4 py-2 text-sm">Exit</span>
                        <span routerLink="/vigi" class="block px-4 py-2 text-sm">Change product</span>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div class="title-section h-10 flex items-center">
        <span class="mx-auto">Profile and need based recommendation</span>
    </div>
    <div *ngIf="selectedProduct.productType==='life'" class="bg-white p-2 user-profile">
        <div class="flex items-center">
            <h2 class="font-bold">Customer’s profile and requirements</h2>
            <img *ngIf="!isEditing" (click)="startEditing(); toggleEditingMode()" class="h-2 w-2 ml-2 cursor-pointer"
                src="../../../../assets/icons/edit-pointers.png" />
        </div>
        <p class="text-gray-700">
            <span [ngClass]="{'missing-data': !lifeCustomerDetails.age, 'normal-data': lifeCustomerDetails.age}">
                {{lifeCustomerDetails.age ? lifeCustomerDetails.age : '?'}}
            </span> years old,
            <span [ngClass]="{'missing-data': !lifeCustomerDetails.gender, 'normal-data': lifeCustomerDetails.gender}">
                {{lifeCustomerDetails.gender ? lifeCustomerDetails.gender : '?'}}
            </span>, living in a
            <span
                [ngClass]="{'missing-data': !lifeCustomerDetails.location, 'normal-data': lifeCustomerDetails.location}">
                {{lifeCustomerDetails.location ? lifeCustomerDetails.location : '?'}}
            </span>, has
            <span
                [ngClass]="{'missing-data': lifeCustomerDetails.familyMembers.length === 0, 'normal-data': lifeCustomerDetails.familyMembers.length > 0}">
                {{lifeCustomerDetails.familyMembers.length > 0 ?
                getCommaSeperatedString(getStringValues(lifeCustomerDetails.familyMembers)) : '?'}}
            </span>; Planning for
            <span
                [ngClass]="{'missing-data': lifeCustomerDetails.lifeGoals.length === 0, 'normal-data': lifeCustomerDetails.lifeGoals.length > 0}">
                {{lifeCustomerDetails.lifeGoals.length > 0 ?
                getCommaSeperatedString(getStringValues(lifeCustomerDetails.lifeGoals)) : '?'}}
            </span>; Has annual income of
            <span
                [ngClass]="{'missing-data': !lifeCustomerDetails.annualIncome, 'normal-data': lifeCustomerDetails.annualIncome}">
                {{lifeCustomerDetails.annualIncome ? lifeCustomerDetails.annualIncome : '?'}}
            </span>
            <span *ngIf="lifeCustomerDetails.healthInsurance === 'yes'">
                and existing life insurance.
            </span>
        </p>


    </div>
    <div *ngIf="selectedProduct.productType==='health'" class="bg-white p-2 user-profile">
        <div class="flex items-center">
        <h2 class="font-bold">Customer’s profile and requirements</h2>
            <img *ngIf="!isEditing" (click)="startEditing(); toggleEditingMode()" class="h-2 w-2 ml-2 cursor-pointer"
                src="../../../../assets/icons/edit-pointers.png" />
        </div>
        <p class="text-gray-700">
            <span [ngClass]="{'missing-data': !age, 'normal-data': age}">
                {{age > 0 ? age : '?'}}
            </span> years old,
            <span
                [ngClass]="{'missing-data': !healthCustomerDetails.gender, 'normal-data': healthCustomerDetails.gender}">
                {{healthCustomerDetails.gender ? healthCustomerDetails.gender : '?'}}
            </span>, living in a
            <span
                [ngClass]="{'missing-data': !healthCustomerDetails.location, 'normal-data': healthCustomerDetails.location}">
                {{healthCustomerDetails.location ? healthCustomerDetails.location : '?'}}
            </span>; Looking to cover
            <span
                [ngClass]="{'missing-data': healthCustomerDetails.members[0].relationship==='', 'normal-data': healthCustomerDetails.members.length > 0}">
                {{healthCustomerDetails.members[0].relationship==='' ? '?' :
                getCommaSeperatedString(getSelectedMembers(healthCustomerDetails.members))}}
            </span> with
            <span
                [ngClass]="{'missing-data': healthCustomerDetails.healthConditions.length === 0, 'normal-data': healthCustomerDetails.healthConditions.length > 0}">
                {{healthCustomerDetails.healthConditions.length > 0 ?
                getCommaSeperatedString(getStringValues(healthCustomerDetails.healthConditions)) : '?'}}
            </span>; Has
            <span [ngClass]="{'missing-data': !getInsuranceString(), 'normal-data': getInsuranceString()}">
                {{getInsuranceString() ? getInsuranceString() : '?'}}
            </span> health insurance.
        </p>

    </div>
    <span *ngIf="!pitchLoading && profileFilled && !isCustomerProfileInsufficient" class="flex justify-center font-bold text-xs">Recommended Plan</span>
    <span *ngIf="isCustomerProfileInsufficient" class="flex justify-center font-normal text-xs font-purple px-6">{{errorMessage}}</span>
    <main *ngIf="!pitchLoading && profileFilled && !isCustomerProfileInsufficient">
        <button aria-label="Slide back" type="button" *ngIf="productRecommendations.length>1"
            class="absolute left-0 p-2 ml-1 bg-opacity-50 rounded-full bg-green"
            (click)="handleCarouselMove(false)">
            <svg width="8" height="14" fill="none" viewBox="0 0 8 14" xmlns="http://www.w3.org/2000/svg"
                class="w-4 h-4">
                <path d="M7 1L1 7L7 13" stroke="#5A4374" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
            </svg>
        </button>
        <button aria-label="Slide forward" id="next" *ngIf="productRecommendations.length>1"
            class="absolute right-0 p-2 mr-1 bg-opacity-50 rounded-full bg-green"
            (click)="handleCarouselMove(true)">
            <svg width="8" height="14" fill="none" viewBox="0 0 8 14" xmlns="http://www.w3.org/2000/svg"
                class="w-4 h-4">
                <path d="M1 1L7 7L1 13" stroke="#5A4374" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
            </svg>
        </button>
        <div class="carousel-container" dir="ltr" #carouse>
            <div class="carousel-slide" *ngFor="let recommendation of productRecommendations; let i = index"
                [ngClass]="{'bg-green': selectedRecommendation === i, 'bg-default': selectedRecommendation !== i}"
                (click)="selectRecommendation(i, recommendation)">
                <div>
                    <div class="flex mt-3 justify-center items-center">
                        <img class="h-4 object-contain mr-2" [src]="recommendation.companyLogo" />
                        <span>{{recommendation.company}}</span>
                    </div>
                    <span class="flex justify-center mt-3 mb-6">{{recommendation.product}}</span>
                    <ul *ngFor="let feature of recommendation.key_features | slice:0:3">
                        <li>{{feature}}</li>
                    </ul>
                    <button type="button" (click)="showMoreDetails(recommendation)"
                        class="px-8 py-3 font-semibold rounded-full bg-gray font-purple know-more-btn">Know
                        more</button>
                </div>
            </div>
        </div>
    </main>
    <div *ngIf="!pitchLoading && profileFilled && !isCustomerProfileInsufficient"
        class="relative flex justify-center my-auto mx-auto compare-products mb-4">
        <button id="compare-button"
            [ngClass]="selectedProductToCompare==='' && selectedRecommendation === null?'navigation-disabled': selectedProductToCompare==='' && selectedRecommendation!=null? 'bg-purple':'bg-green'"
            class="compare-button flex items-center justify-between">
            <img (click)="toggleCompareDropdown($event)" *ngIf="selectedProductToCompare===''" class="compare-icon"
                src="../../../../assets/icons/compare.png" />
            <img (click)="toggleCompareDropdown($event)" *ngIf="selectedProductToCompare!''" class="compare-icon"
                src="../../../../assets/icons/compare-purple.png" />
            <span class="w-full" (click)="toggleCompareDropdown($event)"
                *ngIf="selectedProductToCompare===''">Compare</span>
            <span (click)="toggleCompareDropdown($event)" *ngIf="selectedProductToCompare!''"
                class="font-normal font-purple">Compare with {{selectedCompanyToCompare+' -
                '+selectedProductToCompare}}</span>
            <img (click)="toggleCompareDropdown($event)" *ngIf="selectedProductToCompare===''" class="dropdown-icon"
                src="../../../../assets/icons/compare-dropdown.png" />
            <button *ngIf="selectedProductToCompare!''" (click)="openComparePopup()"
                class="compare-arrow p-0 h-[30px] w-[30px] flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-[24px] w-[24px]" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M12 5l7 7-7 7" />
                </svg>
            </button>
        </button>

        <div *ngIf="isCompareDropdownOpen" class="compare-products-options absolute bottom-full">
            <ul>
                <li (click)="productClicked(option)" *ngFor="let option of compareProductsOptions"
                    [ngClass]="{'bg-green': selectedProductToCompare === option.product}"><span>{{option.company+' -
                        '+option.product}}</span></li>
            </ul>
        </div>
    </div>
    <div *ngIf="!pitchLoading && profileFilled && !isCustomerProfileInsufficient" class="flex justify-center items-center space-x-9 mt-7 mb-5 sales-product-toggle">
        <label [ngClass]="selectedRecommendation === null?'navigation-disabled':''" class="flex items-center justify-center cursor-pointer border rounded border-gray-300">
            <input type="radio" [disabled]="selectedRecommendation === null" (change)="onModuleSelection('sales-pitch')" class="form-radio">
            <span class="ml-2">Sales Pitch</span>
        </label>
        <label [ngClass]="selectedRecommendation === null?'navigation-disabled':''" class="flex items-center justify-center cursor-pointer border rounded border-gray-300">
            <input type="radio" [disabled]="selectedRecommendation === null" (change)="onModuleSelection('product-queries')" class="form-radio">
            <span class="ml-2">Product Queries</span>
        </label>
    </div>
</div>
<div *ngIf="isSkipped">
    <header>
        <div class="container mx-auto flex items-center justify-between">
            <!-- Logo and Name -->
            <div class="flex items-center">
                <button (click)="goBack()" class="focus:outline-none">
                    <svg class="h-8 w-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M19 12H5M12 19l-7-7 7-7"></path>
                    </svg>
                </button>
                <span class="text-xl ml-2 font-bold">Product Recommendation</span>
            </div>
            <!-- Kebab Icon -->
            <div class="flex items-center">
                <button (onblur)="toggleDropdown()" (click)="toggleDropdown()" class="focus:outline-none toggle-button">
                    <img class="h-6 w-6" src="../../../../assets/icons/menu.png" />
                </button>
                <div [ngClass]="{'dropdown-open': isDropdownOpen}"
                    class="header-wrapper absolute bg-white text-black rounded-md shadow-lg overflow-hidden">
                    <div class="navigation-options">
                        <span routerLink="/home" class="block px-4 py-2 text-sm">Exit</span>
                        <span routerLink="/vigi" class="block px-4 py-2 text-sm">Change product</span>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div class="title-section h-10 flex items-center">
        <span class="mx-auto">Profile and need based recommendation</span>
    </div>
    <div class="relative profile-container border p-2 border-gray-300">
        <div class="flex items-center">
            <h2 class="font-bold">Customer profile</h2>
            <img *ngIf="!isEditing" (click)="startEditing()" class="h-2 w-2 ml-2 cursor-pointer"
                src="../../../../assets/icons/edit-pointers.png" />

            <!-- Icon Container -->
            <div class="absolute top-0 right-14 flex space-x-4 items-center icon-container">
                <!-- Voice-to-Text Icon -->
                <img
                src="../../../../assets/icons/mic.png"
                alt="mic"
                class="h-6 w-6 cursor-pointer"
                [ngClass]="{'bg-purple rounded-full p-2': isListening}"
                (click)="toggleVoiceRecognition()"
                />
            
                <!-- Translate Icon -->
                <img
                    src="../../../../assets/icons/translate.png"
                    alt="translate"
                    class="h-6 w-6 cursor-pointer"
                    (click)="translateText()"
                />
            </div>
        </div>

        <textarea id="input" [(ngModel)]="inputValue" [readonly]="!isEditing" *ngIf="isEditing || profileFilled" [(ngModel)]="inputValue"
            class="w-full mt-2 bg-transparent border-none outline-none resize-none overflow-hidden"
            (input)="adjustTextareaHeight($event)">
        </textarea>

        

    <!-- Error and Status Messages -->
    <div *ngIf="isListening" class="text-sm text-gray-500 mt-2">
        Listening... Speak clearly.
    </div>
    <div *ngIf="voiceError" class="text-sm text-red-500 mt-2">
        {{ voiceError }}
    </div>

   
        <button *ngIf="isEditing" (click)="send()"
            class="absolute bottom-2 right-2 text-blue-500 p-0 h-[30px] w-[30px] flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-[24px] w-[24px]" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M12 5l7 7-7 7" />
            </svg>
        </button>
    </div>
    <span *ngIf="!pitchLoading && profileFilled && !isCustomerProfileInsufficient" class="flex justify-center font-bold text-xs">Recommended Plan</span>
    <span *ngIf="isCustomerProfileInsufficient" class="flex justify-center font-normal text-xs font-purple px-4">{{errorMessage}}</span>
    <main *ngIf="!pitchLoading && profileFilled && !isCustomerProfileInsufficient">
        <button aria-label="Slide back" type="button" *ngIf="productRecommendations.length>1"
            class="absolute left-0 p-2 ml-1 bg-opacity-50 rounded-full bg-green"
            (click)="handleCarouselMove(false)">
            <svg width="8" height="14" fill="none" viewBox="0 0 8 14" xmlns="http://www.w3.org/2000/svg"
                class="w-4 h-4">
                <path d="M7 1L1 7L7 13" stroke="#5A4374" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
            </svg>
        </button>
        <button aria-label="Slide forward" id="next" *ngIf="productRecommendations.length>1"
            class="absolute right-0 p-2 mr-1 bg-opacity-50 rounded-full bg-green"
            (click)="handleCarouselMove(true)">
            <svg width="8" height="14" fill="none" viewBox="0 0 8 14" xmlns="http://www.w3.org/2000/svg"
                class="w-4 h-4">
                <path d="M1 1L7 7L1 13" stroke="#5A4374" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
            </svg>
        </button>

        <div class="carousel-container" dir="ltr" #carouse>
            <div class="carousel-slide" *ngFor="let recommendation of productRecommendations; let i = index"
                [ngClass]="{'recommendation-selected': selectedRecommendation === i}"
                (click)="selectRecommendation(i, recommendation)">
                <div>
                    <div class="flex mt-3 justify-center items-center">
                        <img class="h-4 object-contain mr-2" [src]="recommendation.companyLogo" />
                        <span>{{recommendation.company}}</span>
                    </div>
                    <span class="flex justify-center mt-3 mb-6">{{recommendation.product}}</span>
                    <ul *ngFor="let feature of recommendation.key_features | slice:0:3">
                        <li>{{feature}}</li>
                    </ul>
                    <button type="button" (click)="showMoreDetails(recommendation)"
                        class="px-8 py-3 font-semibold rounded-full bg-gray font-purple know-more-btn">Know
                        more</button>
                </div>
            </div>
        </div>
    </main>
    <div *ngIf="!pitchLoading && profileFilled && !isCustomerProfileInsufficient"
        class="relative flex justify-center my-auto mx-auto compare-products mb-4">
        <button id="compare-button"
            [ngClass]="selectedProductToCompare==='' && selectedRecommendation === null?'navigation-disabled': selectedProductToCompare==='' && selectedRecommendation!=null? 'bg-purple':'bg-green'"
            class="compare-button flex items-center justify-between">
            <img (click)="toggleCompareDropdown($event)" *ngIf="selectedProductToCompare===''" class="compare-icon"
                src="../../../../assets/icons/compare.png" />
            <img (click)="toggleCompareDropdown($event)" *ngIf="selectedProductToCompare!''" class="compare-icon"
                src="../../../../assets/icons/compare-purple.png" />
            <span class="w-full" (click)="toggleCompareDropdown($event)"
                *ngIf="selectedProductToCompare===''">Compare</span>
            <span (click)="toggleCompareDropdown($event)" *ngIf="selectedProductToCompare!''"
                class="font-normal font-purple">Compare with {{selectedCompanyToCompare+' -
                '+selectedProductToCompare}}</span>
            <img (click)="toggleCompareDropdown($event)" *ngIf="selectedProductToCompare===''" class="dropdown-icon"
                src="../../../../assets/icons/compare-dropdown.png" />
            <button *ngIf="selectedProductToCompare!''" (click)="openComparePopup()"
                class="compare-arrow p-0 h-[30px] w-[30px] flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-[24px] w-[24px]" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M12 5l7 7-7 7" />
                </svg>
            </button>
        </button>

        <div *ngIf="isCompareDropdownOpen" class="compare-products-options absolute bottom-full">
            <ul>
                <li (click)="productClicked(option)" *ngFor="let option of compareProductsOptions"
                    [ngClass]="{'bg-green': selectedProductToCompare === option.product}"><span>{{option.company+' -
                        '+option.product}}</span></li>
            </ul>
        </div>
    </div>
    <div *ngIf="!pitchLoading && profileFilled && !isCustomerProfileInsufficient" class="flex justify-center items-center space-x-9 mt-7 mb-5 sales-product-toggle">
        <label [ngClass]="selectedRecommendation === null?'navigation-disabled':''" class="flex items-center justify-center cursor-pointer border rounded border-gray-300">
            <input type="radio" [disabled]="selectedRecommendation === null" (change)="onModuleSelection('sales-pitch')" class="form-radio">
            <span class="ml-2">Sales Pitch</span>
        </label>
        <label [ngClass]="selectedRecommendation === null?'navigation-disabled':''" class="flex items-center justify-center cursor-pointer border rounded border-gray-300">
            <input [disabled]="selectedRecommendation === null" type="radio" (change)="onModuleSelection('product-queries')" class="form-radio">
            <span class="ml-2">Product Queries</span>
        </label>
    </div>
</div>
<p-dialog class="compare-products-dialog" [(visible)]="isComparePopupOpen"
    [style]="{'background-color': '#D9EAD5', 'width': '85vw', 'height': '85vh', 'border-radius': '5px'}">
    <p-header>
        <p class="font-normal text-xs"><span class="italic">“{{selectedProduct.company+' -
                '+selectedProduct.product}}”</span> is better for this customer than <span
                class="italic">“{{selectedCompanyToCompare+' - '+selectedProductToCompare}}”</span>, because:</p>
    </p-header>
    <div>
        <div *ngIf="isComparisionPointsLoading" class="flex items-center justify-center mt-14">
            <img class="w-2/3 h-2/3" src="../../../../assets/icons/loader.gif" />
        </div>
        <p *ngIf="isComparisionPointsLoading" class="text-center italic mt-3 mr-3">Loading...</p>
        <div *ngFor="let point of comparisionPoints" class="flex items-center border-b border-gray-300 mb-2 mt=-2">
            <p class="flex-1 text-xs" [innerHTML]="point"></p>
        </div>
    </div>
</p-dialog>
<p-dialog class="compare-products-dialog" [(visible)]="isKnowMoreDialogOpen"
    [style]="{'background-color': '#D9EAD5', 'width': '85vw', 'height': '85vh', 'border-radius': '5px'}">
        <div class="flex mt-3 justify-center items-center">
            <img class="h-4 object-contain mr-2" [src]="selectedRecommendationCompany?.companyLogo" />
            <span>{{selectedRecommendationCompany?.company}}</span>
        </div>
        <span class="flex justify-center mt-3 mb-6">{{selectedRecommendationCompany?.product}}</span>
    <div>
        <ul *ngFor="let point of selectedRecommendationPoints"
            class="flex items-center border-b border-gray-300 mb-2 mt=-2">
            <li class="flex-1 text-xs">{{point}}</li>
        </ul>
    </div>
</p-dialog>
<div *ngIf="isCompareDropdownOpen || isComparePopupOpen || isKnowMoreDialogOpen" class="scrim"></div>